
/* Font Import */
@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@1,300&display=swap');

/* Tailwind Imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Component Classes */
@layer components {

  .etg-heading {
    @apply text-xl md:text-3xl tracking-wider font-medium
  }

  .etg-subHeading {
    @apply text-lg md:text-2xl tracking-wide font-semibold
  }

  .etg-sectionTitle {
    @apply text-base md:text-xl tracking-wide font-medium uppercase text-emerald-900
  }

  .etg-systemText {
    @apply text-sm text-slate-700 italic text-center
  }

  .etg-descriptionText {
    @apply text-xs md:text-sm text-slate-700 italic
  }

  .etg-generalText {
    @apply text-slate-900 text-base md:text-lg tracking-wide
  }

  .etg-userText {
    @apply text-slate-900 text-base md:text-lg tracking-wide
  }

  .etg-emphasizedText {
    @apply uppercase tracking-widest
  }

  .etg-ectorGrowText {
    @apply font-bold underline underline-offset-2 
  }

  .etg-textInput {
    @apply text-black bg-slate-200 p-2 mb-4 m-1 rounded-xl tracking-wide text-base md:text-lg
  }

  .etg-selectInput {
    @apply p-2 rounded-xl
  }

  .etg-inputLabel {
    @apply text-black text-lg p-1
  }

  .etg-inputNumber {
    @apply text-black text-lg p-1 bg-slate-200 rounded-xl
  }
    

  
}

/* .App {

} */

body {
  font-family: 'Exo', sans-serif;
  background-color: #2d2d2d;
  color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
}

